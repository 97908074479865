
#root {
    height: 100%;
}

.main-menu {
    background-color: #001a34;
    width: 275px;
    position: fixed;
    height: 100%;
    left: 0;
    top: 50px;
}

.main-menu-sm {
    background-color: #001a34;
    width: 65px;
    position: fixed;
    height: 100%;
    top: 50px;
}

.menu-item {
    font-size: 18px;
    text-decoration: none;
    color: hsla(0, 0%, 100%, .8);
    padding: 16px 21px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 6px;
}

.menu-item:hover:not(:focus),
.menu-item:focus {
    text-decoration: none;
    background-color: hsla(0, 0%, 100%, .16);
    color: white;
}

.menu-active {
    text-decoration: none;
    background-color: hsla(0, 0%, 100%, .1);
    color: white;
}

.img-instrument {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 3px;
}

#avatar {
    background-image: url('https://skladsystem.ru/wp-content/uploads/2018/11/cropped-logo-e1542890421556.png');
    background-color: white;
    margin-left: 80px;
    width: 120px;
    height: 120px;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
    margin-top: 4px;
    margin-bottom: 4px;
}

.top-bar {
    width: 100%;
    z-index: 99;
}

.top-bar-sm {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 65px;
    right: 0;
    background-color: rgb(255, 255, 255);
    overflow-x: hidden;
    padding-top: 1 0px;
    transition: 0.5s;
}

.top-bar-sm a {
    text-decoration: none;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.instrument-table td {
    text-align: center;
    vertical-align: baseline;
}

.catalog-basket {
    border-color: black;
    border-radius: 2px;
}

.dropmenu {
    display: none;
}

.dropmenu div {
    padding: .5em;
    margin: .5em, .5em, 2px, 2px;
    display: block;
    flex-wrap: wrap;
    border-bottom: #001a34;
}

.dropmenu a {
    text-decoration: none;
    color: #001a34;
}

.dropmenu.show {
    top: 0px;
    display: block;
    inset: 0px auto auto 0px;
    position: absolute;
    transform: translate(0px, 40px);
    background-color: white;
    z-index: 1000;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.styled-block {
    background-color: #f2f5fa;
    border-radius: 4px;
}
@media( min-width: 768px) {
    .dynamic-content-md {
        margin-top: 60px;
        margin-left: 4.5em;
    }    
}
@media (min-width: 992px) { 
    .dynamic-content {
        margin-left: 17.5em;
        margin-top: 60px;
        margin-right: 10px;
    }
 }

 .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 10px solid;
    box-sizing: border-box;
    animation: animloader 1s linear infinite alternate;
  }
  @keyframes animloader {
    0% {
      border-color: rgb(10, 10, 10) rgb(65, 65, 64) rgb(162, 162, 163) rgba(248, 247, 247);
    }
    33% {
      border-color: rgb(65, 65, 64) rgb(162, 162, 163) rgba(248, 247, 247) rgba(10, 10, 10);
    }
    66% {
      border-color: rgb(162, 162, 163) rgb(248, 247, 247) rgb(10, 10, 10) rgba(65, 65, 64);
    }
    100% {
      border-color: rgb(248, 247, 247) rgb(10, 10, 10) rgb(65, 65, 64) rgb(162, 162, 163);
    }
  } 