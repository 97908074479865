:root {
  height: 95%;
}
body {
  height: 95%;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
