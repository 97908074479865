
.image-gallery-item {
	position: relative;
	text-align: center;
	padding: 2px;
	border: 1px solid #dee2e6;
	border-radius: .25rem;
	margin-right: 2px;
}
.image-gallery-item:hover {
	border-color: black;
}

.active {
	border-color: black;
}
.bi-person-plus {
	font-size: 18px;
	cursor : pointer;
}
.bi-person-x {
	font-size: 18px;
	cursor : pointer;
}
.btn-toggle {
	color: #fff;
	background-color: #a3a3a3;
	border-color: #28a745;
	cursor: pointer;
}

.btn-toggle-red {
	color: #fff;
	background-color: #a3a3a3;
	border-color: #28a745;
	cursor: pointer;
}

 .btn-toggle-selected {
	color: #fff;
	background-color: #1e7e34;
	border-color: #1c7430;
	z-index: 1;
}

.btn-toggle-selected-red {
	color: #fff;
	background-color: #e41e10;
	border-color: #e41e10;
	z-index: 1;
}

.btn-toggle:hover {
    color: #fff;
    background-color: #01a024;
    border-color: #1e7e34;
}
.btn-toggle:focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 .2rem rgba(72,180,97,.5);
}
.btn-toggle-red:hover {
    color: #fff;
    background-color: #a00101;
    border-color: #a00101;
}
.btn-toggle-red:focus {
    color: #fff;
    background-color: #a00101;
    border-color: #a00101;
    box-shadow: 0 0 0 .2rem rgba(72,180,97,.5);
}