* {box-sizing:border-box}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 8px;
  color: rgb(177, 177, 177);
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 1px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #f1f1f1; 
  font-size: 11px;
  padding: 8px 6px;
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  opacity:0;
  text-align: center;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  margin-left: auto;
  margin-right: auto;
  width: 250px;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot1 {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.activeImage, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade1 {
  animation-name: fade1;
  animation-duration: 1.5s;
}

@keyframes fade1 {
  from {opacity: .4}
  to {opacity: 1}
}

.fade1:hover .text{
    cursor: pointer;
    opacity: 1;
}