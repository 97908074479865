@charset "UTF-8";

.registration-left-side {
	background: #001a34;
	width: 40%;
	position: fixed;
	bottom: 0;
	top: 0;
	
}
.registration-right-side {
	margin-left: 1em;
	position: fixed;
	left:45%;
	top: 15%;
}